.footer1 {
    background-color: #2c3e50;
    color: #ecf0f1;
   
}

.footer1 h4 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ecdbba;
    font-weight: 600;
    text-transform: uppercase;
}

.footer-links1,
.footer-contact1 {
    list-style-type: none;
    padding: 0;
}

.footer-links1 li,
.footer-contact1 li {
    margin-bottom: 10px;
  
}

.footer-links1 li a {
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.3s;
    padding: 2px;
    font-size: 14px;
}

.footer-links1 li a {
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.2s ease, background-color 0.2s ease, padding 0.2s ease;
    border: 1px solid #ffffff5e;
    padding: 4px;
}

.footer-links1 li a:hover {
    color: #ffffff;
    background-color: #d82e7a;
    padding: 2.2px;
    border-radius: 3px; /* Optional: adds rounded corners to the background */
}


.footer-contact1 li {
    font-size: 14px;
}

.footer-contact1 li i {
    margin-right: 10px;
    color: #d82e7a;
}



.footer1 .col-md-4 {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
}
.footerMain{
    font-weight: bold;
   
    color: #f39c12;
    display: flex;
    padding-top: 15px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 1.25rem;
    justify-content: center;
}
    .footerUls {
        border-right: 1px solid #ffffff7a;
     }