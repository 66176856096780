.top-header {
    background-color: #f8f9fa; /* Light background color */
    padding: 10px 0; /* Padding for vertical space */
    font-size: 14px; /* Font size */
    border-bottom: 1px solid #e0e0e0; /* Subtle border at the bottom */
  }
  
  .top-header .custom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .top-header-left {
    color: #333; /* Text color */
  }
  
  .top-header-right {
    color: #007bff; /* Bootstrap primary color for links */
    display: flex;
    align-items: center;
  }
  
  .top-header-right .fa-phone-alt {
    margin-right: 5px; /* Space between phone icon and number */
  }
  
  @media (max-width: 768px) {
    .top-header .custom-container {
      flex-direction: column;
      text-align: center;
    }
  
    .top-header-left, .top-header-right {
      margin-bottom: 5px;
    }
  
    .top-header-right {
      justify-content: center;
    }
  }
  .anchor{
    color: #f8f9fa;
    text-decoration: none;
  }
  .anchor:hover{
    color: #000000;
    text-decoration: none;
  }