.image-gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Gap between images */
  justify-content: space-between;
  background-color: black;
  padding: 40px;
  margin: 50px 0px 50px 0px;
  position: relative; /* Relative positioning for container */
}

.image-item {
  flex: 1 1 calc(20% - 10px); /* 20% width for 5 boxes with space in between */
  max-width: calc(20% - 10px);
  background-color: black; /* Black background for the container */
  color: white; /* Text color */
  text-align: center;
}

.image-box {
  overflow: hidden; /* Ensure the image does not overflow its container */
}

.zoom-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container without stretching */
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

.image-box:hover .zoom-image {
  transform: scale(1.1); /* Zoom image within the box */
}

/* Centering the buttons */
.button-container {
  display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    position: relative;
    left: 43%;
}

.product-buttons,
.enquire-now-below {
  margin: 0 10px; /* Space between the two buttons */
  background: #babec200;
  border: 1px solid #ffffff78;
  padding: 7px;
  border-radius: 7px;
  text-align: center;
}

.product-buttons img {
  width: 24px; /* Adjust the size of the WhatsApp icon */
}

.enquire-now-below:hover,
.product-buttons:hover {
  color: #d82e7a;
  text-decoration: none;
}

.image-price1 {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.image-price1:hover {
  color: #d82e7a;
  text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .image-item {
      flex: 1 1 calc(25% - 10px); /* 4 boxes per row */
      max-width: calc(25% - 10px);
  }
}

@media (max-width: 992px) {
  .image-item {
      flex: 1 1 calc(33.33% - 10px); /* 3 boxes per row */
      max-width: calc(33.33% - 10px);
  }
}

@media (max-width: 768px) {
  .image-item {
      flex: 1 1 calc(50% - 10px); /* 2 boxes per row */
      max-width: calc(50% - 10px);
  }
}

@media (max-width: 576px) {
  .image-item {
      flex: 1 1 100%; /* 1 box per row */
      max-width: 100%;
  }
}
.image-link {
  text-decoration: none;
}

.image-link:hover .zoom-image {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
