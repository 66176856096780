.faq-section {
  padding: 2rem;
  max-width: 100%; /* Adjusted to use Bootstrap's container width */
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
}

.faq-item {
  border: 1px solid #dddddd40;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  margin: 6px;
}

.faq-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-question {
  background-color: #f5f5f5;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #e0e0e0;
}

.faq-answer {
  padding: 0 1rem;
  background-color: #fff;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
}
.faq-item.open .faq-answer {
  max-height: fit-content;
  padding: 1rem;
}

.faq-icon {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.faq-item.open .faq-icon {
  transform: rotate(180deg);
  transition-duration: 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-section {
    padding: 1rem;
  }

  .faq-question h3 {
    font-size: 16px;
  }

  .faq-icon {
    font-size: 1rem;
  }
}

.faq-question h3 {
  font-size: 18px;
}

/* Display two FAQs in one line */
@media (min-width: 768px) {
  .faq-item {
    flex: 1 0 48%; /* Adjusted for two items per row */
  }
}
