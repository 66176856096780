.catalogue-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.catalogue-section {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.catalogue-box {
  width: calc(20% - 1rem);
  margin: 0.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.catalogue-box:hover {
  transform: scale(1.05);
}

.catalogue-image {
  width: 100%;
  height: auto;
}

.inner-boxes {
  display: flex;
  flex-wrap: wrap;
}

.inner-box {
  width: 100%;
  padding: 0.5rem;
}

.inner-box-image {
  width: 100%;
  height: auto;
}

.pdf-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

/* Container for PDF Viewer */
.pdf-viewer-container {
  overflow: hidden; /* Prevent horizontal scrolling */
  height: 100vh; /* Full viewport height */
  background-color: white; /* White background */
}

/* PDF Viewer */
.pdf-viewer {
  width: 100%;
  height: 100%;
  border: none;
}
/* Container for PDF Viewer */
.pdf-viewer-container {
  overflow: hidden; /* Prevent horizontal scrolling */
  height: 100vh; /* Full viewport height */
  background-color: white; /* White background */
  border: none; /* Remove border */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

/* CatalogueView.css */

.pdf-image {
  width: 50px;
  height: auto;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
}

.pagination {
  justify-content: center;
  padding: 0;
  margin-top: 20px;
  display: flex;
}

.page-item {
  margin: 0 5px;
  position: relative;
  transform: skew(-10deg);
}

.page-link {
  background-color: #d82e7a; /* Pink shade */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: inline-block;
  transform: skew(10deg);
}

.page-link:hover {
  background-color: #c71b65; /* Darker shade on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.page-link:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(216, 46, 122, 0.4);
}

.page-link:disabled,
.page-link.disabled {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  cursor: not-allowed;
  color: #6c757d;
  box-shadow: none;
}

.page-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: skew(10deg);
  z-index: -1;
}
.pdfimg{
  margin: 100px 0px 100px 0px;
}
.pdfimginner{
  padding: 10% 0px 0px 0px;
}
.card1{
  background: #f7f7f7;
    border-radius: 12px;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.page-link:hover {
  
  color: #000000;}
  /* Ensure the container for the PDF viewer and pagination takes full width on mobile */
@media (max-width: 768px) {
  .pdf-viewer {
    width: 100%;
    height: 400px; /* Adjust height if needed */
  }

  .pagination {
    flex-direction: column;
    margin: 10px 0;
  }

  .page-item {
    margin: 5px 0;
  }

  .page-link {
    padding: 8px 12px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for better readability */
    transform: skew(0deg); /* Remove skew for mobile */
  }

  .page-item:before {
    display: none; /* Hide the pseudo-element on mobile */
  }

  .pdfimg {
    margin: 20px 0; /* Adjust margin for mobile */
  }

  .pdfimginner {
    padding: 5% 0; /* Adjust padding for mobile */
  }

  .card1 {
    padding: 1rem; /* Adjust padding for mobile */
  }
}


.page-link {
  padding: 8px 12px; /* Adjust padding for smaller screens */
  font-size: 14px; /* Adjust font size for better readability */
  transform: skew(0deg); /* Remove skew for mobile */
}
.pdfedit{
  border: 1px solid #a4a0a0;
    padding: 13px;
}
.pdfedits{
  display: flex;
    align-items: center;
}
.hideThis{
  display: none;
}