.contact-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 1200px;
  background-color: #f4f4f4;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.contact-info,
.contact-form {
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info h2,
.contact-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.address-section p {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.map-section {
  margin-top: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #d82e7a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #c52869;
}

.contacts a {
  text-decoration: none;
  color: #d82e7a;
}

.contacts a:hover {
  color: #00306e;
}
