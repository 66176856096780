.categories-container {
    box-sizing: border-box;
  }
  
  h2.categories-header {
    text-align: center; /* Center the category title */
    margin-bottom: 30px;
    font-size: 2rem; /* Adjust font size */
    color: #333; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  h2.categories-header i {
    margin: 0 10px; /* Space between text and icon */
    color: #D4AF37; /* Gold color */
    font-size: 2rem; /* Size of icons */
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 categories in a row for larger screens */
    gap: 20px; /* Space between items */
  }
  
  .category-box {
    background-color: #f4f4f447;
    border: 1px solid #cccccc3b;
    border-radius: 10px; /* Rounded corners */
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  
  
  .category-box.active {
    background-color: #ffffff;
    color: black;
    border: .2px solid #cccccca6;
    
  }
  .category-box:hover,
  .category-box:focus,
  .category-box.active {
    box-shadow: -2px -2px 5px -2px #d82e7a;
    background: #f595c077;
  }
  
  .category-icon {
    font-size: 3rem; /* Adjusted size for icons */
  }
  
  .products-container {
    margin-top: 30px; /* Space from categories */
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 products in a row on large screens */
    gap: 20px; /* Space between items */
  }
  
  .product-box {
    background-color: #fff; /* White background for products */
    border: 1px solid #cccccc2b; /* Border for product boxes */
  
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
    font-size: 1.4rem;
  }
  
  .product-box img {
    max-width: 100%; /* Responsive image */
    margin-bottom: 10px; /* Space below image */
    width: 500px;
  }
  .product-box img {
    transition: transform .8s ease, background-color 2s ease;
  }
  
  .product-box img:hover {
    transform: scale(1.03);
    background-color: #f0f0f0; /* Replace with your desired background color */
  }
  
  .product-box p {
    text-align: center; /* Center product title */
    font-weight: bold; /* Bold text */
    color: #333; /* Text color */
  }
  
  .product-actions {
    display: flex;
    justify-content: space-between; /* Space actions evenly */
    width: 100%;
  }
  
  .product-button {
    background-color: #ffffff;
    color: #d82e7a;
    padding: 6px 5px 6px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s;
    flex: 1 1;
    margin: 5px;
    font-size: 14px;
    border: 1px solid;
  
  }


.product-button:hover {
    background-position: right center; /* Change the background position for the effect */
    background-color: #000000; /* Fallback for browsers that don't support background-image transition */
    color: #fff;
    text-decoration: none;
}

  
  @media (max-width: 768px) {
    .categories-grid {
      grid-template-columns: repeat(2, 1fr); /* 3 categories in a row on smaller screens */
      gap: 10px; /* Adjust gap for smaller screens */
    }
  
    .products-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 products in a row on smaller screens */
      gap: 10px; /* Adjust gap for smaller screens */
    }
  
    .product-actions {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
      align-items: center; /* Center actions */
    }
  
    .product-button {
      width: 100%; /* Full width buttons on smaller screens */
      margin-bottom: 10px; /* Space between buttons */
    }
  }
  .darkbg{
    background: black;
  }
  .product-button1{
    height: 20px;
    width: 20px !important;
  
  }
  .product-box img {
    max-width: 100%;
    margin-bottom: 2px;
 
}


/* Box styles */
.category-box {
  background-color: #f0f0f0; /* Adjust the background color */
  border: 1px solid #ccc; /* Optional border */
  padding: 10px; /* Padding inside each box */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.category-box img {
  max-width: 100%; /* Ensure image fits within the box */
  height: auto; /* Maintain aspect ratio */
  max-height: 100px; /* Adjust as needed */
}

/* Responsive design for smaller screens */
/* Apply styles only for screens smaller than 768px */
@media only screen and (max-width: 991px) {
  .categories-grid {
    grid-template-columns: repeat(3, 1fr); /* Three columns for small screens */
  }

  .headingStyle {
    font-size: 14px; /* Adjust font size for small screens */
  }
}
