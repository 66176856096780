.info-section {

    padding: 20px;
    margin-bottom: 20px;
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .info-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .info-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .info-description {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    max-width: 800px;
  }
  
  @media (min-width: 768px) {
    .info-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .info-image {
      margin-right: 20px;
      flex: 1;
    }
  
    .info-description {
      flex: 2;
      text-align: left;
    }
  }
  .corporate-gifting-section {
   
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
   
}

.corporate-gifting-section h2 {
    color: #d82e7a; /* Dark blue color for the heading */
    margin-bottom: 15px;
}

.corporate-gifting-section p {
    color: #333; /* Dark grey for the text */
    margin-bottom: 15px;
}

.corporate-gifting-section ul {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
}

.corporate-gifting-section li {
   
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px; /* Rounded corners for list items */
    color: #333; /* Text color for list items */
}

.corporate-gifting-section li b {
    color: #d82e7a;
}
.value-corporate-gifting-section {
    background-color: #f9f9f9; /* Light background for readability */
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 20px; /* Space above the section */
}

.value-corporate-gifting-section h2 {
    color: #002855; /* Dark blue color for the heading */
    margin-bottom: 15px;
}

.value-corporate-gifting-section p {
    color: #333;
    margin-bottom: 24px;
    align-items: center;
    display: flex;
    
    justify-content: center;
}

.value-corporate-gifting-section .benefits {
    display: flex;
    flex-direction: column;
}

.value-corporate-gifting-section .benefits-section {
    margin-bottom: 20px; /* Space between the sections */
}

.value-corporate-gifting-section h3 {
    align-items: center;
    display: flex;
    color: #002855;
    gap: 30px;
    margin-bottom: 10px;
    font-size: 18px;
}

.value-corporate-gifting-section ul {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
}

.value-corporate-gifting-section li {
    
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px; /* Rounded corners for list items */
    color: #333; /* Text color for list items */
    height: 4em;

}

.value-corporate-gifting-section li b {
    color: #002855; /* Highlighted text color for the bold labels */
}


/* Container for the toggle sections */
.toggle-container {
    display: flex;
    width: 100%;
    
    
    font-family: Arial, sans-serif;
}

/* Style for the questions half */
.questions-half {
   
    height: 24rem;
}

.questions-half .toggle-title {
    cursor: pointer;
    margin: 15px 0;
    padding: 10px;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
    font-size: 18px;
}

.questions-half .toggle-title:hover {
    background-color: #555;
    color: #e0e0e0;
}

/* Style for the answers half */
.answers-half {
    width: 50%;
  
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333;
    overflow-y: auto;
}

/* Style for the toggle content */
.toggle-content {
    display: none;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 5px;
    margin-bottom: 20px;
  
    transition: opacity 0.5s ease;
}

.toggle-content.active {
    display: block;
    opacity: 1;
}

/* Style for each bullet point */
.toggle-content ul {
    list-style-type: disc;
    margin: 0;
    padding: 0 20px;
}

.toggle-content li {
    margin-bottom: 10px;
    padding-left: 10px;
    line-height: 1.6;
   
    padding-left: 20px; /* Spacing for bullets */
}


/* Adding smooth transitions for the content visibility */
.toggle-content {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.toggle-content.active {
    opacity: 1;
    height: 300px;
}
/* Custom scrollbar styles for the entire component */
.toggle-container {
    display: flex;
    width: 100%;
    margin-bottom: 40px; 
   
}

/* Style for the questions half */
.questions-half {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f9fa;
    color: #333;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #c2185b #f8f9fa; /* For Firefox */
}

/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.questions-half::-webkit-scrollbar {
    width: 12px;
}

.questions-half::-webkit-scrollbar-track {
    background-color: #f8f9fa;
}

.questions-half::-webkit-scrollbar-thumb {
    background-color: #c2185b;
    border-radius: 10px;
}

.questions-half::-webkit-scrollbar-thumb:hover {
    background-color: #a31248;
}


/* Custom scrollbar styles for WebKit browsers (Chrome, Safari) */
.answers-half::-webkit-scrollbar {
    width: 12px;
}

.answers-half::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.answers-half::-webkit-scrollbar-thumb {
    background-color: #c2185b;
    border-radius: 10px;
}

.answers-half::-webkit-scrollbar-thumb:hover {
    background-color: #a31248;
}

/* Style for the questions */
.questions-half .toggle-title {
    cursor: pointer;
    margin: 15px 0;
    padding: 15px;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
    font-size: 18px;
    background-color: #fff;
}

.questions-half .toggle-title:hover,
.questions-half .toggle-title.active {
    background-color: #f1f1f1;
    color: #c2185b;
}

/* Style for the answers */
.answers-half {
    width: 50%;
  
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333;
    overflow-y: auto;
}

/* Style for the toggle content */
.toggle-content {
    display: none;
    padding: 20px;
    background-color: #f9f9f9;
    color: #333;
    border-radius: 5px;
    margin-bottom: 20px;
   
    transition: opacity 0.5s ease-in-out;
}

.toggle-content.active {
    height: 24rem;
    display: block;
    opacity: 1;
}

/* Style for each bullet point */
.toggle-content ul {
    list-style-type: circle;
    margin: 6px;
    padding: 27px;
    background: white;
    text-align: left;
}



/* Adding a subtle shadow and rounded corners for aesthetics */
.toggle-content {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.iconss{
    padding: 5px;
    height: 57px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: -2px 1px 7px -2px black;
}
@media only screen and (max-width: 768px) {
    .answers-half, .questions-half {
        width: 100%;
        box-sizing: border-box; /* Ensure padding and border are included in the width */
        margin: 0; /* Remove any default margin */
        padding: 0; /* Remove any default padding */
    }
    .toggle-container {
        display: block;
       
    }
}
/* InfoSection.css */

/* Overall container styling */
.toggle-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f8f9fa; /* Light background color */
    border-bottom: 1px solid #e0e0e0; /* Subtle border at the bottom */
  }
  /* Hide element on mobile view */
@media (max-width: 767px) {
    .toggle-container {
        display: none;
      
    }
}

  /* Styling for the questions section */
  .questions-half {
    flex: 1;
    border-right: 1px solid #e0e0e0; /* Border between questions and answers */
    padding-right: 20px;
  }
  
  .toggle-title {
    padding: 10px;
    cursor: pointer;
    color: #333; /* Text color */
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s ease;
  }
  
  .toggle-title.active {
    background-color: #007bff; /* Bootstrap primary color */
    color: #ffffff; /* Text color for active state */
  }
  
  .toggle-title:hover {
    background-color: #007bff; /* Hover background color */
    color: #ffffff; /* Text color for hover */
  }
  
  /* Styling for the answers section */
  .answers-half {
    flex: 1;
    padding-left: 20px;
  }
  
  .toggle-content {
    display: none;
  }
  
  .toggle-content.active {
    display: block;
  }
  
  @media (max-width: 768px) {
    .toggle-container {
      flex-direction: column;
      text-align: center;
    }
  
    .questions-half, .answers-half {
      border-right: none;
      padding-right: 0;
      padding-left: 0;
    }
    .value-corporate-gifting-section h3 {
       
        margin-bottom: 10px;
        position: relative;
        left: 13px;
        margin-top: 10px;
        line-height: 5;
    }
  }
  