/* Policies.css */

/* General Container Styling */
.privacy-policy-container {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  /* Title Styling */
  .privacy-policy-title {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Intro Paragraph Styling */
  .privacy-policy-intro {
    font-size: 18px;
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  
  /* Section Styling */
  .privacy-section {
    margin-bottom: 30px;
  }
  
  .privacy-section h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 2px solid #d82e7a;
    padding-bottom: 5px;
  }
  
  /* Address Section Styling */
  .privacy-section .privacy-address {
    font-style: normal;
    line-height: 1.6;
  }
  
  .privacy-section .privacy-address a {
    display: block;
    color: #d82e7a;
    text-decoration: none;
    margin-top: 5px;
  }
  
  .privacy-section .privacy-address a:hover {
    text-decoration: underline;
  }
  
  /* List Styling */
  .privacy-section .privacy-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-section .privacy-list li {
    font-size: 16px;
    color: #555;
  }
  
  /* Footer Styling */
  .privacy-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin-top: 40px;
  }
  
  .privacy-footer .footer-link {
    color: #d82e7a;
    text-decoration: none;
  }
  
  .privacy-footer .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Paragraph Styling */
  .privacy-section .privacy-text {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Link Styling for Email and Phone */
  .privacy-section .privacy-link {
    color: #d82e7a;
    text-decoration: none;
  }
  
  .privacy-section .privacy-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    .privacy-policy-title {
      font-size: 30px;
    }
  
    .privacy-policy-intro {
      font-size: 16px;
    }
  
    .privacy-section h2 {
      font-size: 22px;
    }
  
    .privacy-footer {
      padding: 15px;
    }
  }
  