.onsale-container {
    padding: 40px 20px;
    box-sizing: border-box;
  }
  
  h2.onsale-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  h2.onsale-header i {
    margin: 0 10px;
    color: #D4AF37;
    font-size: 2rem;
  }
  
  .onsale-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Allow wrapping for small screens */
  }
  
  .onsale-main,
  .onsale-side {
    display: flex;
    flex-direction: column;
    gap: 20px; 
  }
  
  .onsale-main {
    flex: 2; /* Larger flex for the main image */
  }
  
  .onsale-side {
    flex: 1; /* Smaller flex for side images */
  }
  
  .image-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Ensure images maintain their aspect ratio correctly */
  .image-wrapper img {
    width: 100%; /* Full width of the wrapper */
    height: auto; /* Maintain intrinsic height */
    object-fit: cover; /* Maintain aspect ratio while cropping */
  }
  
  .overlay {
    position: absolute;
    bottom: 0; /* Align text to the bottom */
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center;
  }
  
  h3, p {
    margin: 0;
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  p {
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .onsale-content {
      flex-direction: column;
    }
  
    .onsale-main,
    .onsale-side {
      width: 100%; 
    }
  }
      