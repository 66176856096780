
.card {
    border-radius: 11px;
    border: none;
    width: 100%;
    height: 200px;
    perspective: 1000px; /* Adds depth to the flip effect */
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
}

.card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.section-title2 {
    text-align: center;
    font-size: 2rem;
    color: #f1c40f;
    margin-bottom: 30px;
}

.card-front {
   /* background-color: #d82e7a;  Dark background for the front */
    background-image: linear-gradient(30deg, #cb9832 52%, #d4a5568a 97%);
}

.card-back {
    background-color: #000; /* Mustard color for the back */
    transform: rotateY(180deg);
    border: 1px solid #f9b302;
}
.letterspaces{
    letter-spacing: 3px;
    transition: transform 1s ease;
}
.letterspaces:hover {
    transform: scale(1.1);
    z-index: 10; /* z-index doesn't need transition duration */
}
.card-front h3, .card-back p {
    margin: 0;
    text-align: center;
    padding: 7px;
}

@media (max-width: 768px) {
    .card {
        height: 150px;
    }
}
.cardFsection{
    background: black;
}
.givespace{
    padding-bottom: 80px;
}