.services-header {
    position: relative;
    text-align: center;
    color: white;
}

.hero {
    position: relative;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.hero h1 {
    font-size: 2.5rem;
    margin: 0;
    z-index: 2;
    position: relative;
  
}

.hero-img {
    width: 100%;
    height: auto;
    display: block;
    z-index: 0;
}

.form-container {
    position: absolute;
    bottom: 20px;
    top: 2%;
    left: 75%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 600px;
    z-index: 2;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 1.8rem;
    }

    .form-container {
        bottom: 10px;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .hero h1 {
        font-size: 1.5rem;
    }

    .form-container {
        width: 95%;
    }
}
.services-header{
    overflow-x: hidden;
}


.hero-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the hero section without distortion */
    display: block;
    z-index: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        height: 60vh; /* Reduce height for smaller screens */
    }
}

@media (max-width: 480px) {
    .hero {
        height: 50vh; /* Further reduce height for very small screens */
    }
}
