.faq-container1 {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: none;
  }
  
  .faq-item1 {
    border-bottom: 1px solid #ddd;
    padding: 8px 0;
    cursor: pointer;
  }
  
  .faq-question1 {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000000;
    padding: 10px;
    color: antiquewhite;
  }
  
  .faq-answer1 {
    max-height: 0;
   overflow-x: hidden;
   overflow-y: auto;
    transition: max-height 0.3s ease;
    height: auto;
  }
  
  .faq-answer1.open {
    max-height: 200px; /* Adjust as needed */
  }
  
  .faq-icon1 {
    font-size: 24px;
  }
  @media (max-width: 767px) {
    .faq-container1 {
      
        display: block;
      
    }
}