.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    position: relative;
    text-align: center;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  .close-btn:hover {
    color: #d82e7a;
  }
  
  .popup-content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .popup-content form input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .submit-btn {
    padding: 10px 20px;
    background-color: #d82e7a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #b02460;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .popup-content {
      width: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .popup-content {
      width: 100%;
      padding: 15px;
    }
  
    .close-btn {
      font-size: 20px;
    }
  
    .submit-btn {
      padding: 8px 15px;
    }
  }
/* popup.css */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 40%;
  max-width: 600px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.hubspot-form-container {
  /* Add any specific styles needed for the HubSpot form */
  width: 100%;
  min-height: 400px; /* Ensure the container is large enough */
}

/* Loader styles (adjust as needed) */
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
