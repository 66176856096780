.category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #c97d7d;
    border-radius: 8px;
    padding: 16px;
  }
  
  .category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 9%;
    position: relative;
    margin: 0 10px;
  }
  
  .category-icon2 {
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
  }
  .category-link:hover{
    text-decoration: none;
  }
  .category-label {
    font-size: 12px;
    color: #333;
    text-decoration: none;
  }
  .category-label:hover{
    text-decoration: none;
    color: #c97d7d;
  }
  .vertical-divider {
    height: 50px;
    width: 1px;
    background-color: #c97d7d;
    position: absolute;
    right: -13px;
    top: 0;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 16px;
    font-size: 24px;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .category-list {
      justify-content: space-around;
    }
    
    .category-item {
      margin: 0;
      flex: 1 0 28%; /* Makes sure 3 items fit per row */
    }
  
    .vertical-divider {
      display: none;
    }
  }
  .category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #c97d7d;
    border-radius: 8px;
    padding: 16px;
  }
  
  .category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 9%;
    position: relative;
    margin: 0 10px;
  }
  
  .category-icon2 {
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
  }
  
  .category-label {
    font-size: 12px;
    color: #333;
  }
  
  .vertical-divider {
    height: 50px;
    width: 1px;
    background-color: #c97d7d;
    position: absolute;
    right: -13px;
    top: 0;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 16px;
    font-size: 24px;
    color: #333;
  }
  
  @media (max-width: 1200px) {
    .category-item {
      width: 12%; /* Adjusts for medium screens, 8 items in a row */
    }
  }
  
  @media (max-width: 992px) {
    .category-item {
      width: 16%; /* Adjusts for medium screens, 6 items in a row */
    }
  }
  
  @media (max-width: 768px) {
    .category-list {
      justify-content: space-around;
      padding: 8px; /* Adjust padding for smaller screens */
    }
    
    .category-item {
      width: 30%; /* Adjusts to fit 3 items per row on tablets */
      margin: 10px 0; /* Adjust margin for vertical spacing */
    }
  
    .vertical-divider {
      display: none; /* Hides vertical dividers on mobile */
    }
  }
  
  @media (max-width: 480px) {
    .category-item {
      width: 45%; /* Adjusts to fit 2 items per row on very small screens */
      margin: 5px 0; /* Adjust margin for compact spacing */
    }
    
    .category-icon2 {
      width: 35px; /* Reduces icon size on very small screens */
      height: 35px;
    }
    
    .category-label {
      font-size: 10px; /* Reduces font size on very small screens */
    }
  }
  