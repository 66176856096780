/* Custom container width */
.custom-container {
  width: 94vw;
  max-width: 100%;
  margin: 0 auto;
}

/* Align the nav items to the right */
.navbar-nav.ml-auto {
  margin-left: auto;
}

/* Add space between nav items */
.nav-item {
  margin-left: 10px;
  margin-right: 10px;
}

/* Styling for mega menu */
.mega-menu {
  width: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
}

/* Handle the vertical line and other styles */
.navbar-nav > li {
  position: static;
}

.navbar-nav .dropdown-menu {
  width: 100%;
  left: 0;
  right: 0;
  padding: 1rem;
}

/* Tab content styling */
.tab-content {
  display: none;  /* Hide all tab contents by default */
}

.tab-content.active {
  display: block;  /* Show the active tab content */
}

/* Additional Styling for Dropdown Headers */
.dropdown-header {
  font-weight: bold;
  color: #333;
}

/* Add vertical line on desktop */
@media (min-width: 768px) {
  .mega-menu .container .row .col-md-2 {
    border-right: 1px solid #ccc;
  }
}

/* Ensure the mega menu is responsive */
@media (max-width: 768px) {
  .mega-menu {
    padding: 0;
  }

  .dropdown-menu {
    box-shadow: none !important;
  }
}

/* Top header styles */
.top-header {
  background-color: #d82e7a;
  padding: 10px 0;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  /* Not sticky, so we don't add position: sticky */
}

.top-header .custom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-header-left {
  color: #fff;
}

.top-header-right {
  color: #fff;
  display: flex;
  align-items: center;
}

.top-header-right .fa-phone-alt {
  margin-right: 5px;
}

/* Responsive adjustments for top header */
@media (max-width: 768px) {
  .top-header .custom-container {
    flex-direction: column;
    text-align: center;
  }

  .top-header-left, .top-header-right {
    margin-bottom: 5px;
  }

  .top-header-right {
    justify-content: center;
  }
}

/* Bottom header (navigation) sticky styles */
.navbar {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Ensure the navbar has full width container */
.navbar .custom-container {
  width: 100%;
}

.adjustWidth{
  width: 273px;
}
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.dropdown-toggle {
  white-space: nowrap;
  color: rgba(0, 0, 0, .5);
}


.services-menu {
  width: 100%;
  padding: 2rem;
  max-height: 400px;
  overflow-y: auto;
}

.services-menu .row {
  margin-right: 0;
  margin-left: 0;
}

.services-menu .col-md-3 {
  padding-right: 10px;
  padding-left: 10px;
  border-right: 1px solid #ccc; 
}

.services-menu .col-md-3:last-child {
  border-right: none;
}

.services-menu .img-fluid {
  border-radius: 10px;
  margin-bottom: 20px;
}

.dropdown-item {
  padding: 9px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

.dropdown-item:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .services-menu .d-none {
    display: none ;
  }
}

.mega-menu .two-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

@media (max-width: 768px) {
  .mega-menu .two-columns {
    grid-template-columns: repeat(2, 1fr); 
  }

  #tabs-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
  }
}
.navbar-light .navbar-brand {
  color: rgb(33 32 32 / 90%);
  font-size: 28px;
  letter-spacing: 3.0px;
  font-family: 'slick';
}
/* Define the fade-in-down animation */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ensure the dropdown menu appears on hover with animation */
.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  animation: fadeInDown 0.3s ease-in;
}

/* Hide the dropdown menu initially */
.dropdown-menu {
  display: none;
  opacity: 0;
}

/* Dropdown item hover styles */
.dropdown-item:hover {
  color: #d82e7a;
  text-decoration: none;
  background-color: #d82e7a24;
}
.searchicon{
  border: 1px solid #7163631c;
    height: 30px;
    border-radius: 6px;
}
.navbar-light .navbar-toggler {
 
  border: none; 
}
button:focus {
 
 outline: none; 
}
.dropdown-menu {
 margin: 0px;
 border: none;
  }

  .dropdown-item.active, .dropdown-item:active {
    color: #d82e7a;
    text-decoration: none;
    background-color: #d82e7a29;}

    /* Styling for the logo image */
.logo-img {
  width: 34%;  /* Adjust the size of the logo */
  height: auto; /* Keep the aspect ratio */
  display: block;
}

/* Optional: Add hover effects */
.logo-img:hover {
  opacity: 0.8;  /* Slightly transparent on hover */
}

/* Responsive design */
@media (max-width: 768px) {
  .logo-img {
    width: 120px; /* Smaller logo on mobile */
  }
}
.navbar-brand {
  
   padding-top: 0rem; 
  padding-bottom: 0rem;
   margin-right: 0rem; 
}

.dropdown-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.navbar .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
}
