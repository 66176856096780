.four-images-section {
    display: flex;
    justify-content: space-between;
    margin: 50px 0px 50px 0px;
  }
  
  .image-container {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .image-container:hover img {
    transform: scale(1.1);
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(216 46 122 / 40%); /* Semi-transparent black overlay1 */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .image-container:hover .overlay1 {
    opacity: 1;
  }
  
  .details {
    text-align: center;
    color: white;
  }
  
  .price-button {
    background-color: #d82e7a;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .price-button:hover {
    background-color: #e75480;
  }
  
  .arrow {
    margin-top: 20px;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .image-container:hover .arrow {
    transform: translateX(10px) scale(1.2);
  }
  
  @media (max-width: 768px) {
    .four-images-section {
      flex-direction: column;
    }
  
    .image-container {
      margin: 10px 0;
    }
  }
  