

 .about-us-container {
    max-width: 100%;
    margin: 0 auto;
} 

.section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333; /* Dark color for contrast */
}

.about-us-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about-us-section {
    display: flex;
    align-items: center;
    gap: 20px;
    
}

.section-image {
    width: 50%;
    height: 430px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.section-text h3 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #c2185b; /* Accent color for headers */
    
}

.section-text p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555; /* Dark gray text for better readability */
}

.s {
    list-style-type: disc;
    padding-left: 20px;
}

.liCont {
    margin-bottom: 10px;
}

li b {
    color: #c2185b; /* Accent color for list item headers */
}
.buttons {
    color: #fff;
    background-color: #000;
    border: 1px solid #ccc; /* Default border color */
    padding: 10px 20px; /* Adjust padding as needed */
    text-decoration: none;
    width: 10em;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease; /* Smooth transition for color, background-color, and border-color */
}

.buttons:hover {
    color: #c2185b;
    background-color: #f5f5f5;
    text-decoration: none;
    border: 1px solid #c2185b;
    
}
.about-us-content {
    margin: 30px;
    position: relative;
  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
    color: #000;
    background-attachment: fixed;
    overflow: hidden; /* Ensures the overlay stays within the container */
    text-align: left;
}

.about-us-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% opacity */
    z-index: 1; /* Make sure the overlay is above the background image */
}

.about-us-content * {
    position: relative;
    z-index: 2; /* Ensures text and other content are above the overlay */
}

.about-us-content h2, .about-us-content h3, .about-us-content p {
    margin: 10px 0;
    text-align: left;
}

.about-us-content ul {
    margin: 10px 0;
    padding-left: 20px;
}

.about-us-content li {
    margin-bottom: 5px;
}
.colorbg{
    border-left: 5px solid #d82e7a;
    background: #2c3e50;
    padding: 34px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
}
.listsit{
    list-style-type: disclosure-closed;
    line-height: 2;
    margin-top: 20px;
}
.ctaButton{
    display: flex;
    align-items: center;
    justify-content: center;
}