/* Ensure the container takes full width and the cards align properly */
.container {
  max-width: 1200px; /* Adjust as needed */
}

/* Basic styling for cards */
.card {
  border: none;
  border-radius: 8px;
  overflow: hidden;
}

/* Hover effect for cards */
.hover-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.hover-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Card text styling */
.card-text {
  font-weight: bold;
  color: #333;
  transition: color 0.3s;
}

.hover-card:hover .card-text {
  color: #c97d7d;
}

/* Responsive layout */
@media (max-width: 768px) {
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
    
@media (max-width: 768px){
.twoinMobile{
             display: grid;
            grid-template-columns: repeat(2, 1fr); /* Two columns of equal width */
            gap: 10px; }
            .modern-lamps{
              display: none;
            }
            .card-body {
             
              padding: 0.8rem;
          }
          }
          .unique-link {
            text-decoration: none;
            color: inherit;
          }
          
          .unique-link:hover .unique-card {
            transform: scale(1.01);
            
            transition: transform 0.3s ease;
          }
          .unique-link:hover{
            text-decoration: none;
            color: #ff0296;
          }
          