/* Ensure the slider takes full width and centers its content */
.home-slider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    background: #000;
  }
  
  .home-slider-text {
    flex: 1;
    padding: 20px;
  }
  
  .home-slider-text h1 {
    font-size: 4.5rem;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .home-slider-text p {
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .home-slider-text .btn {
    margin-right: 10px;
    font-size: 16px;
  }
  
  .home-slider {
    flex: 2;
    max-width: 600px; /* Ensure images don't stretch too much */
  }
  
  .slider-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 600px; /* Ensure the image does not exceed the given height */
  }
  
  /* Ensure the carousel buttons don't overlap the header */
  .slick-arrow {
    z-index: 1;
  }
  
  .slick-dots {
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    .home-slider-container {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
  
    .home-slider {
      max-width: 100%;
    }
  
    .home-slider-text {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .home-slider-text .btn {
      margin-bottom: 10px;
    }
  
    .home-slider-text h1 {
      font-size: 2.5rem;
    }
  
    .home-slider-text p {
      font-size: 1.2rem;
    }
    .slick-dots {
      position: relative !important;
    
    }
  }
  
  @media (max-width: 480px) {
    .home-slider-container {
      display: flex;
      flex-direction: column;
     
    }
  
    .home-slider-text {
      text-align: center;
      margin-bottom: 10px;
    }
  
    .home-slider-text .btn {
      margin-bottom: 8px;
      font-size: 16px;
    }
  
    .home-slider-text h1 {
      font-size: 2rem;
    }
  
    .home-slider-text p {
      font-size: 1rem;
    }
    .slick-dots {
      position: relative !important;
    
    }
  }
  .btn-primary {
    color: #fff;
    background-color: #d82e7a;
    border-color: #d82e7a;
}
.btn-secondary {
  color: #fff;
    background-color: #000000;
    border-color: #d82e7a;
}
.btn-secondary:hover {
  color: #000;
  background-color: #ffffff;
  border-color: #d82e7a;
}
.gift-for-text {
  font-size: 30px;
  color: #fff;
}

.typed-text {
  font-size: 3rem;
  color: #fff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #000000;
  border-color: #ffffff;
}
.slick-prev {
  left: 0px;
}
.gift-for-text{
  font-size: 2rem;
}