.footer {
    background-color: #2c3e50;
    color: white;
    padding: 40px 0;
    font-size: 14px;
  }
  
  .footer-heading {
    font-weight: bold;
    margin-bottom: 15px;
    color: #f39c12;
  }
  
  .footer a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer a:hover {
    color: #f1c40f;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: #ecf0f1;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #f1c40f;
  }
  
  .footer-text {
    margin-top: 20px;
    font-size: 12px;
    color: #bdc3c7;
  }
  
  .footer p, .footer address {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .footer .col-md-3 {
      margin-bottom: 20px;
    }
  
    .footer .col.text-center {
      margin-top: 20px;
    }
  }
  .logs{
    letter-spacing: 3px;
    font-family: 'slick';
    font-size: 24px;
  }
  .nomargin{
    margin-top: 20px;
   
  }
  .footeradd{
    margin-top: 17px;
    letter-spacing: 0.5px;
  }
  .topmar{
    margin-top: 15px;
  }
  .image-row {
    display: flex; /* Aligns items in a row */
    gap: 10px; /* Adds space between images if needed */
    margin-bottom: 10px; /* Adds space below each row of images */
}

.image-row img {
    width: 200px; /* Sets the width of the images */
    height: 100px; /* Sets the height of the images */
    object-fit: cover; /* Ensures images cover the specified dimensions */
}
.imageZoomm {
  transition: transform 0.3s ease; /* Adds smooth transition for transform changes */
}

.imageZoomm:hover {
  transform: scale(1.1); /* Applies scale transformation on hover */
  cursor: no-drop;
}
.footerdisclaimer{
  margin-bottom: 20px !important;
}
.readMore{
  color: #f39c12 !important;
}
.readMore:hover{
  color: #f5f3ef !important;
}
.list-unstyled li{
  padding: 2px;
}