.three-columns-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for larger screens */
    gap: 20px; /* Space between columns */
    padding: 10px 0px; /* Section padding */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  
  .column-box {
    background-color: #000000; /* White background for columns */
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* Ensure rounded corners affect the image */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for transform and shadow */
  }
  
  .column-box:hover {
    transform: translateY(-10px); /* Lift up on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .column-box img {
    width: 100%; /* Ensure full width */
    display: block; /* Make it a block element */
  }
  
  .column-box-content {
    padding: 20px; /* Padding for text content */
    text-align: center; /* Center text */
  }
  
  .column-box h3 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 10px;
    padding: 12px 10px 0px 10px;
  }
  
  .column-box p {
  
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 20px;
    padding: 0px 10px 0px 10px;
  }
  
  .view-more-btn {
    background: none; /* No background */
    border: none; /* No border */
    color: #d82e7a; /* Bootstrap primary color */
    font-size: 1rem; /* Button font size */
    cursor: pointer; /* Pointer cursor on hover */
    text-decoration: none; /* Underlined text */
    transition: color 0.3s; /* Smooth transition for color change */
    padding: 0px 10px 10px 10px;
  }
  
  .view-more-btn:hover {
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .three-columns-section {
      grid-template-columns: 1fr; /* Single column for smaller screens */
      gap: 20px; /* Larger gap for better spacing on smaller screens */
    }
  }
  h2.three-columns-header i {
    margin: 0 10px; /* Space between text and icon */
    color: #D4AF37; /* Gold color for the icons */
    font-size: 2rem; /* Size of icons */
  }
  h2.three-columns-header {
    text-align: center; /* Center the header title */
    margin-bottom: 30px;
    font-size: 2rem; /* Font size for the header */
    color: #333; /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .three-columns-container {
    padding: 50px 20px; /* Section padding */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }