.testimonials-container {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background for contrast */
    text-align: center;
    margin: 60px;
}

.section-title {
    font-size: 2rem;
    color: #f1c40f; /* Mustard color */
    margin-bottom: 30px;
}

.testimonial-item {
    padding: 20px;
    border-radius: 10px;
    background-color: #333; /* Dark background for testimonials */
    color: #fff;
    margin: 0 auto;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-quote {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-style: italic;
}

.testimonial-author {
    font-size: 1rem;
    color: #f1c40f; /* Mustard color for the author */
}

.slick-dots {
    bottom: -30px; /* Adjusts the position of dots */
}

.slick-prev, .slick-next {
    color: #f1c40f; /* Mustard color for navigation arrows */
}
.slick-next {
    right: 0px;
}
