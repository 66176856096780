/* Style for the button */
.stickySide {
  position: fixed;
  top: 50%;
  right: 0;
  background-color: #d82e7a;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transform: translateY(-50%);
  z-index: 1000;
  transition: background-color 0.3s ease;
  writing-mode: vertical-rl;
text-orientation: upright;
letter-spacing: -2.0px;
}

.stickySide:hover {
  background-color: #c2185b;
}

/* Modal styles */
.unique-modal {
  position: fixed;
  top: 2%;
  left: 19%;
  width: 81%;
  height: 100%;
 
  display: none;
  justify-content: end;
  align-items: center;
  z-index: 1001;
}

.unique-modal.open {
  display: flex; /* Show the modal when open */
} 

.unique-modal-content {
  background: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    width: 40%;
    max-width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 92vh;
}

.unique-modal-content h3 {
  color: #746767;
  text-align: center;
  margin-bottom: 1rem;
}

.unique-form-group {
  margin-bottom: 1rem;
}

.unique-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #ddd;
}

.unique-form-group input,
.unique-form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #333;
  color: #ddd;
}

.unique-form-group input::placeholder,
.unique-form-group textarea::placeholder {
  color: #999;
}

.unique-form-group textarea {
  resize: vertical;
  height: 47px;
}

.unique-sidebar-button {
  background-color: #d82e7a;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  transition: background-color 0.3s ease;
}

.unique-sidebar-button:hover {
  background-color: #2c3e50;
}

.close-button {
  margin-bottom: 5px;
  background: transparent;
  color: #757070;
  border: none;
  /* padding: 0.5rem; */
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-size: 1.7rem;
}

.close-button:hover {
  border: 1px solid black;

}

@media (max-width: 1024px) {
  .unique-modal-content {
    max-width: 90%; /* Adjust the width for smaller screens */
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .unique-modal-content {
    width: 90%;
  }
}


@media (max-width: 768px) {
  .unique-modal-content {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .unique-form-group input,
  .unique-form-group textarea {
    font-size: 0.8rem;
  }

  .unique-sidebar-button {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.unique-modal.open .unique-modal-content {
  animation: fadeInRight 0.5s ease-in-out;
}

.unique-modal.close .unique-modal-content {
  animation: fadeOutRight 0.5s ease-in-out forwards;
}

@media (max-width: 768px){
  .button-container {

    left: 25%;
}
.stickySide {
  position: fixed;
  top: 70%;
 
  padding: 0.2rem;
  font-size: 12px;

}

}


@media (max-width: 768px) {
  .unique-modal-content {
    width: 90%;
    height: 90vh;
  }
  
  .hs-input {
    height: 21px !important;
    line-height: 16px;
  }
}

.unique-form-group select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #333;
  color: #ddd;
}

.unique-form-group select::placeholder {
  color: #999;
}
.css-13cymwt-control{
  background-color: hsl(0deg 0% 100% / 17%) !important;
}
.css-19bb58m::placeholder {
  color: #999; /* Placeholder color */
}
.css-19bb58m input::placeholder {
  color: #999; /* Placeholder color for consistency */
}
/* Control with a selected value */
.react-select__control--is-selected {
  background-color: white !important; /* Change background to white */
  color: black; /* Change text color to black */
}

/* Single value text color after selection */
.react-select__single-value {
  color: black; /* Ensure the text color is black when selected */
}

/* Placeholder behavior when focused */
.react-select__control--is-focused .react-select__placeholder {
  color: black; /* Change placeholder color to black when selected */
}

/* Maintain the dropdown indicator and clear indicator colors */
.react-select__dropdown-indicator,
.react-select__clear-indicator {
  color: #333; /* Adjust the indicator color to match the white background */
}

/* Adjustments for selected option and control */
.react-select__control--is-selected .react-select__control {
  border-color: #ddd; /* Keep border consistent */
}

/* Optional: Adjusting border color for consistency */
.react-select__control {
  border-color: #ddd;
}

.react-select__control--is-focused,
.react-select__control--is-selected {
  box-shadow: none; /* Remove shadow on focus/selection */
  border-color: #ddd; /* Maintain a consistent border */
}

/* Responsive Design */
@media (max-width: 480px) {
  .react-select__control {
    font-size: 0.8rem;
    padding: 0.4rem;
  }

  .react-select__single-value,
  .react-select__placeholder {
    font-size: 0.8rem;
  }
}
.hs-form-field span {
  color: aliceblue !important;
}


span{
  color: aliceblue !important;
}
.unique-modal.hidden {
  display: none;
}

/* Hide the form on mobile view */
@media (max-width: 768px) {

  .stickySide{
    display: none;
  }
  .unique-modal{
    display: none;
  }
  .unique-modal-content{
    display: none;
  }
  .whatsapp-message{
    display: block;
  }
}

/* WhatsApp Icon Container */
.whatsapp-icon-container {
  position: fixed;
 
    bottom: 2%;
  right: -14%;
  z-index: 1002;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.whatsapp-icon-container:hover {
  transform: scale(1.1);
}

/* WhatsApp Icon */
.whatsapp-icon {
  width: 60px;
  height: 60px;
  animation: bounce 2s infinite;
  background: #ffffff;
    border-radius: 30px;
}
/* Hide on desktop and tablet views */
@media (min-width: 768px) {
  .whatsapp-message {
    right: 30rem;
  }
  .whatsapp-icon-container{
    left: 80rem;
        bottom: 1rem;
  }
  
  
}

/* Show on mobile view */
@media (max-width: 767px) {
  .whatsapp-message {
    display: block; /* or the desired display property */
  }
  .unique-modal.open {
    display: none; /* Show the modal when open */
  } 
}

/* Animation for bouncing the WhatsApp icon */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* WhatsApp Message */
.whatsapp-message {
  position: relative;
  right: 12rem;
  font-weight: 600;
  margin-left: 10px;
  font-size: 0.8rem;
  border: 1px solid #0000005c;
  color: #333;
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1.5s ease-in-out infinite;
}

/* Animation for the message */
@keyframes fadeIn {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

