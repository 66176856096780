/* Importing a modern font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
body {
    font-family: 'PT Sans', sans-serif;
    overflow-x: hidden;
  }

h2{
    font-size: 2rem !important;
}
p{
    font-size: 1rem !important;
    letter-spacing: 0.4px !important;
}


.container1 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.content {
    margin-bottom: 2rem;
}

.features {
    margin-bottom: 2rem;
}

.features h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.features ul {
    list-style-type: disc;
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
}

.features li {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: end;
}
.gifting-image {
    height: auto;
    width: 410px;
}

.content {
    border: 0.2px solid #fff0a9;
    background: #ffffff;
    padding: 35px;
    border-radius: 12px;
}

.giftBg {
    background-image: url('/public/images/corbgimg.jpg');
    background-size: cover; /* Ensure the image covers the element */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent image from repeating */
    height: 300px; /* Adjust height as needed */
    width: 100%; /* Ensure full width */
    display: flex; /* Optional: Use flexbox for centering content */
    align-items: center; /* Optional: Center content vertically */
    justify-content: center; /* Optional: Center content horizontally */
    color: white; /* Optional: Text color for contrast */
    text-align: center; /* Optional: Center text */
}

.image-container {
    position: relative;
    width: 100%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
}
.centerimg{
    text-align: center;
    margin-bottom: 38px;
}

.background-image {
    width: 57%; /* Adjust as needed */
    height: auto; /* Adjust as needed */
}

.foreground-image {
    justify-content: end;
    position: absolute;
    top: 8%;
    display: flex;
    right: -30%;
    transform: translate(-51%, -10%);
    width: 56%;
    height: auto;
    z-index: 1;
}

.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*Media Queries for Responsive Design */
@media (max-width: 767.98px) {
    .foreground-image {
        position: absolute;
        top: -9%;
        right: 0;
        left: 0;
        transform: translate(0, 0);
       
       
        z-index: 1;
        margin: 14px;
    }
    .image-container1 {
        position: relative;
        width: 100%;
        height: auto;
        margin-top: 59px;
    }
    }
    .section-partlheight{
        line-height: 1.7;
        margin-top: 15px;
    }
    .section-part{
        margin-top: 5%;
        background-color: #000000;
        padding: 7%;
    }
    .section-parthead{
        text-align: left;
        color: #fff;
    }
    .section-partpara{
        text-align:justify;
        color: #fff;
        line-height: 1.7;
    }
   

    .content {
        padding: 20px;
    }


/* Media Queries for Responsiveness */

/* Devices with max-width 575.98px */
@media (max-width: 575.98px) {
    .gifting-image {
        width: 100%;
        height: auto;
    }

    .giftBg {
        height: 200px; /* Adjust height as needed for smaller screens */
    }

    .content {
        padding: 15px;
    }

    .box-content {
        font-size: 14px;
    }
}

/* Devices with max-width 767px and min-width 576px (tablets and small desktops) */
@media (min-width: 576px) and (max-width: 767px) {
    .unique-gift-item {
        width: calc(33.33% - 20px); /* 3 boxes in a row for tablets */
        height: 140px; /* Adjusted height for tablets */
    }

    
    
    .unique-row {
        justify-content: space-between; /* Spacing for tablet view */
    }
    
    .unique-gift-text {
        max-width: 100%; /* Ensure text fits in the box */
    }

    .gifting-image {
        width: 100%;
        height: auto;
    }

    .giftBg {
        height: 250px; /* Adjust height as needed for tablets */
    }

    .content {
        padding: 20px; /* Increased padding for tablets */
    }

    .box-content {
        font-size: 16px; /* Adjusted font size for tablets */
    }
}


.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Container styling */
.container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Background and container styling */
/* .uniqueGiftBg {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), no-repeat center center;
    background-size: cover;
    padding: 30px;
    border-radius: 15px;
} */

/* Paragraph styling */
.paraBold {
    font-size: 20px; /* Increased font size for better readability */
    font-weight: 700; /* Bold text */
    color: #fff;
    text-align: center;
    max-width: 90%; /* Ensures paragraph scales with screen size */
    margin-bottom: 20px; /* Space below the paragraph */
}

/* Unordered list styling */
.unlist {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: space-around; /* Space items evenly */
}

/* Row styling */
.unique-row {
    display: flex;
    justify-content: space-between; /* Even spacing for boxes */
    flex-wrap: wrap; /* Wraps to next line if necessary */
    margin-bottom: 20px; /* Space below rows */
}

/* Individual box styling */
.unique-gift-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 20px;
    background-color: #00000073;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgb(255 255 255 / 25%);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(20% - 20px); /* 5 boxes in a row */
    height: 180px; /* Increased height */
    text-align: center;
}

/* Hover effect */
.unique-gift-item:hover {
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

/* Text inside the box */
.unique-gift-text {
    font-weight: 600;
    color: #ffffff; /* Darker text color for contrast */
    text-align: center;
    max-width: 180px; /* Adjusted width for better fit */
    margin: 0;
}

/* Media Queries for Responsiveness */

/* Tablets and smaller desktops */
@media (max-width: 1200px) {
    .unique-gift-item {
        width: calc(25% - 20px); /* 4 boxes in a row */
        height: 160px; /* Slightly reduced height */
    }
}

/* Smaller tablets and larger mobile devices */
@media (max-width: 768px) {
    .unique-gift-item {
        width: calc(33.33% - 20px); /* 3 boxes in a row */
        height: 140px; /* Slightly reduced height */
    }
}

/* Mobile devices */
@media (max-width: 480px) {
    .unique-gift-item {
        width: calc(100% - 20px); /* Full width on mobile */
        height: auto; /* Automatic height */
    }

    .paraBold {
        font-size: 18px; /* Slightly smaller font on mobile */
    }
    
    .unique-row {
        justify-content: center; /* Center items on mobile */
    }
    
    .unique-gift-text {
        max-width: 100%; /* Ensure text fits in the box */
    }
}


.heading1{
    text-align: center;
}

.gift-ideas-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    padding: 40px 20px;
    background-position: center;
    background-repeat: no-repeat;
    background: linear-gradient(0deg, rgba(0, 0, 0, 50%), rgba(0, 0, 0, 50%)), url('/public/images/corGiftingimg2.jpg') no-repeat center center;
    background-attachment: fixed;
}

@media (max-width: 768px) {
    .gift-ideas-container{
        background-size: cover;
    }
}

.gift-ideas-content {
    max-width: 900px;
    width: 100%;
}

.section-title1 {
    font-size: 2.2rem;
    color: #ffffff;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}

.gift-ideas-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.gift-idea-item {
    position: relative;
    background-color: #00000061;
    color: white;
    border-radius: 10px;
    padding: 20px;
    width: calc(50% - 20px); /* Adjust to fit 2 items per row */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

.gift-idea-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.gift-idea-pointer {
    position: absolute;
    top: 50%;
    left: -20px; /* Adjust based on box size */
    width: 15px;
    height: 15px;
    background-color: #f1c40f; /* Mustard color for pointer */
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    transform: translateY(-50%);
}

.gift-ideaHeading {
    padding: 17px;
}

.gift-idea-text {
    margin: 0;
    font-size: 16px;
    color: white;
}

/* Mobile and Tablet Responsive Styles */
@media (max-width: 768px) {
    .section-title1 {
        font-size: 1.8rem; /* Smaller font size for smaller screens */
        margin-bottom: 20px;
    }
    
    .gift-ideas-boxes {
        flex-direction: column; /* Stack items vertically */
        gap: 10px; /* Reduce gap */
    }
    
    .gift-idea-item {
        width: 100%; /* Full width for each item */
        margin-bottom: 10px; /* Add spacing between items */
    }
    
    .gift-idea-pointer {
        left: -10px; /* Adjust pointer position for smaller screens */
    }
    
    .gift-idea-text {
        font-size: 14px; /* Smaller text size */
    }
}

@media (max-width: 480px) {
    .gift-ideas-container {
        padding: 30px 10px; /* Reduce padding for smaller screens */
        background-attachment: scroll; /* Disable fixed background on mobile for smoother scrolling */
    }
    
    .section-title1 {
        font-size: 1.6rem; /* Further reduce font size for very small screens */
    }
    
    .gift-idea-item {
        padding: 15px; /* Reduce padding inside the item */
    }
    
    .gift-idea-pointer {
        left: -8px; /* Adjust pointer position for very small screens */
        width: 12px; /* Scale down the pointer size */
        height: 12px; /* Scale down the pointer size */
    }
    
    .gift-idea-text {
        font-size: 13px; /* Smaller text size for very small screens */
    }
}

.lastPara {
    color: #ffffff;
    font-size: 17px;
    padding-top: 27px;
    text-align: center;
    margin-top: 20px;
}

/* Additional Responsive Adjustments */
@media (max-width: 480px) {
    .lastPara {
        font-size: 15px; /* Further reduce text size for mobile */
        padding-top: 20px;
    }
}

/* content and iage section boxes*/
/* Ensure proper padding and margins for the section */
.new-section {
    padding: 20px;
  }

  .zooms{
    

    display: flex;
    align-items: center;
  
  }

  /* Make sure images and content boxes are responsive */
  .gifting-image1 {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }
  
  .box-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .box {
    padding: 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    color: #4c4c4c;
  }
  
  /* Background colors for boxes */
  .box-one {
    background-color: #33333361;
  }
  
  .box-two {
    background-color: #f4f4f4;
  }
  
  /* Hover effect for zoom */
  .gifting-image1:hover, .box:hover {
    transform: scale(1.05);
  }
  
  /* Responsive styles for mobile devices and tablets */
  @media (max-width: 768px) {
    .box-container {
      flex-direction: column;
    }
  
    .box {
      padding: 15px;
    }
  }
  
  /* Responsive styles for larger screens */
  @media (min-width: 769px) {
    .box-container {
      flex-direction: column;
    }
  }
  .textYellow{
    color: #cb9834;
  }
  .latestsec{
    padding: 4px 30px 50px 32px;
    line-height: 1.8;
    margin-bottom: 5px;
    border-radius: 7px;
  }
  .headingnew{
    color: #d82e7a;
  }
  .textWhite{
    color: white;
  }

  .css-1dimb5e-singleValue:focus:not(:placeholder-shown) {
    color: #fff; /* Change text color to white */
    background-color: #000; /* Optional: Change background color to black for contrast */
  }
  .section-part .col-md-5{
    align-content: center;
  }