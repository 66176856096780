.home-section-container {
  width: 100%;
  box-sizing: border-box;
}

.home-section-content {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.home4ttab {
  background: #fbfbfb;
}

.home-section-left,
.home-section-right {
  margin-top: 50px;
  flex: 1 1; /* Flex-grow and flex-basis for responsive scaling */
  padding: 8px;
  margin-bottom: 50px;
  border-radius: 10px;
  margin-left: 6px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for larger screens */
  grid-template-rows: repeat(2, 1fr);
  gap: 9px;
}

.grid-box {
 
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid-box1 {
  background: #000;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50px 0px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid-box1ttext {
  color: #fff;
  text-align: center;
}

.grid-box img {
  max-width: 100%;
  border-radius: 0px 50px 0px 50px;
}

.grid-box h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.grid-box p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #666;
}

.home-section-right h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.home-section-right p {
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .home-section-content {
    flex-direction: column; /* Stack elements vertically */
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
    /* Adjust grid template rows if needed */
    grid-template-rows: auto; /* Allow rows to adjust to content size */
  }

  .grid-box1, .grid-box {
    flex: 1 1 auto;
  }
}
/* Style for the arrow button */
.arrow-button {
  background: none; /* No background */
  border: none;     /* No border */
  color: #007bff; /* Bootstrap primary color */
  font-size: 1rem; /* Adjust font size */
  cursor: pointer; /* Cursor indicates clickable button */
  display: flex;
  align-items: center;
}

.arrow-button .fa-arrow-right {
  margin-left: 5px; /* Space between text and arrow */
}

/* Button at the bottom of the right section */
.right-bottom-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #d82e7a;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid;
}
.right-bottom-button {
  transition:  0.2s, color 0.2s;
}
.right-bottom-button:hover {
  background-color: #d82e7a;
  color: white;
}
.d-flex{
  display: flex;
}
@media (max-width: 768px){
.home-section-right{
  margin-top: 0px;
}}